

/**
 * Function to format and replace resources.
 * {1}, {2} = params
 * @params {String} str: String to find and replace.
 * @params {Array} array: Array of params.
 */
export const format = (str, array) => {
    array.forEach((param, index) => {
        str = str.replace("{" + (index+1) + "}", param);
    });
    return str;
}
