import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Resources from "../../resources/afisofom-resources";
import Drawer from '@material-ui/core/Drawer';
import SmallCustomButton from "./small-button";
import {
  FaFacebookF, FaInstagram, FaLinkedinIn,
  FaMap,
  FaPhone, FaTwitter
} from "react-icons/fa"
import { useSiteContent } from "../hooks/use-site-content"

const paperProps = {
  style: {
    width: '60%'
  }
};

const MenuMobileContent = styled.div`
  display: block;
  height: 100vh;
  font-family: var(--font-footer-normal);
  background-color: var(${Resources.menu_mobile.bg_color});
`;

const MenuMobListWrapper = styled.div `
  display: grid;
  grid-template-rows: 2fr 1.3fr;
`

const MenuMobileList = styled.div`
  padding: 4vh 30px 0;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  height: 40vh;
`;

const MenuMobileLink = styled.a`
  color: var(${Resources.menu_mobile.link.color});
  font-family: var(${Resources.menu_mobile.link.font});
  font-size: ${Resources.menu_mobile.link.size};
  text-decoration: none;
  text-transform: uppercase;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.link.size_med};
  }

  :hover {
    cursor: pointer;
  }
`;

const MenuMobileLinkAlter = styled.a`
  color: var(${Resources.menu_mobile.link_alter.color});
  font-family: var(${Resources.menu_mobile.link_alter.font});
  font-size: ${Resources.menu_mobile.link_alter.size};
  text-decoration: none;
  text-transform: uppercase;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.link_alter.size_med};
  }

  :hover {
    cursor: pointer;
  }
`;

const MenuMobileFooter = styled.div`
  background-color: var(${Resources.menu_mobile.footer.bg_color});
  padding: 0 30px;
  display: grid;
  height: 35vh;
  justify-content: center;
  align-items: center;
`;

const MenuMobileIconLegend = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr;
  justify-content: flex-start;
  align-items: center;
  padding-top: 15px;

  .map_add_holder:hover {
    cursor: pointer;
  }
  
  .footer-icon {
    font-size: ${Resources.menu_mobile.footer.icon_size};
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .footer-icon {
      font-size: ${Resources.menu_mobile.footer.icon_size_med};
    }
  }
`;

const MenuMobileFooterText = styled.p`
  font-family: var(${Resources.menu_mobile.footer.font});
  font-size: ${Resources.menu_mobile.footer.size};
  color: var(${Resources.menu_mobile.footer.color});
  margin: 0;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: ${Resources.menu_mobile.footer.size_med};
  }
`;

const PrimaryNavbarIconsList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  
  .icon-primary {
    font-size: ${Resources.navbar.primary.icon.size} !important;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .icon-primary {
      font-size: ${Resources.menu_mobile.footer.icon_size_med} !important;
    }
  }
`;

const PrimaryNavbarIcon = styled.a`
  color: var(${Resources.navbar.primary.icon.color});
  font-family: var(${Resources.navbar.primary.icon.font});
  font-size: ${Resources.navbar.primary.icon.size};
  text-decoration: none;
  margin-right: 22px;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: 44px;
  }
`;

const MobileNavbar = ({ isOpen, onBackdropClick }) => {
  const siteContent = useSiteContent()
  return (
    <Drawer PaperProps={paperProps}
            open={isOpen}
            onBackdropClick={() => onBackdropClick()}
            anchor="right">
      <div className="container">
        <MenuMobileContent>
          <MenuMobListWrapper>
            <MenuMobileList>
              {
                Resources.navbar.links.map((link, key) =>
                  <MenuMobileLink key={key}
                                  onClick={() => onLinkClick(link.to)}>
                    {link.label}
                  </MenuMobileLink>
                )
              }
              <MenuMobileLinkAlter onClick={() => onClickClient()}>
                {Resources.menu_mobile.link_alter.label}
              </MenuMobileLinkAlter>
            </MenuMobileList>
            <SmallCustomButton label={Resources.menu_mobile.cust_button.label}
                               view={Resources.menu_mobile.cust_button.view}
                               onClick={() => onLinkClick(Resources.menu_mobile.cust_button.to)}
                               containerStyle={{"display": "grid"}}/>
          </MenuMobListWrapper>
          <MenuMobileFooter>
            <MenuMobileIconLegend>
              <FaMap className="footer-icon" />
              <MenuMobileFooterText className="map_add_holder" onClick={() => openMap(siteContent.footer.map_url)}>
                {
                  siteContent.footer.address.map((line, key) => (<React.Fragment key={key}>{line} <br/></React.Fragment>))
                }
              </MenuMobileFooterText>
            </MenuMobileIconLegend>
            <MenuMobileIconLegend>
              <FaPhone className="footer-icon" />
              <MenuMobileFooterText>
                {
                  siteContent.footer.lista_telefonos.map((phone, key) => (<React.Fragment key={key}>{phone} <br/></React.Fragment>))
                }
              </MenuMobileFooterText>
            </MenuMobileIconLegend>
            <PrimaryNavbarIconsList>
              <PrimaryNavbarIcon href={siteContent.navbar.social_links.fb}
                                 target="_blank">
                <FaFacebookF className="icon-primary" />
              </PrimaryNavbarIcon>
              <PrimaryNavbarIcon href={siteContent.navbar.social_links.twitter}
                                 target="_blank">
                <FaTwitter className="icon-primary" />
              </PrimaryNavbarIcon>
              <PrimaryNavbarIcon href={siteContent.navbar.social_links.linkedin}
                                 target="_blank">
                <FaLinkedinIn className="icon-primary" />
              </PrimaryNavbarIcon>
              <PrimaryNavbarIcon href={siteContent.navbar.social_links.instagram}
                                 style={{ marginRight: 0 }}
                                 target="_blank">
                <FaInstagram className="icon-primary" />
              </PrimaryNavbarIcon>
            </PrimaryNavbarIconsList>
          </MenuMobileFooter>
        </MenuMobileContent>
      </div>
    </Drawer>
  );
}

const openMap = (url) => {
  window.open(url, '_blank');
};

const onLinkClick = (to) => {
  const element = document.getElementById(to);
  if (element) {
    const position = element.offsetTop - Resources.navbar.offset_top;
    window.scrollTo(0, position);
  }
};

const onClickClient = () => {
  console.log('client');
};

MobileNavbar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onBackdropClick: PropTypes.func.isRequired
};

export default MobileNavbar;
