import React, { Component } from "react"
import { GoogleApiWrapper, Map, Marker } from "google-maps-react"
import styled from "styled-components"
import Resources from '../../resources/afisofom-resources.json'
import { useSiteContent } from "../hooks/use-site-content"

const CustomMapWrapper = styled.div`
  width: ${Resources.contact_form.map.width};
  height: ${Resources.contact_form.map.height};
`

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100%",
}

const style = {
  width: "100%",
  height: "100%",
}

const mapStyle = [
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": Resources.contact_form.map.third_color,
      },
      {
        "lightness": "-10",
      },
    ],
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": Resources.contact_form.map.primary_color,
      },
    ],
  },
  {
    "featureType": "administrative.province",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": Resources.contact_form.map.primary_color,
      },
    ],
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": Resources.contact_form.map.primary_color,
      },
    ],
  },
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": Resources.contact_form.map.secondary_color,
      },
      {
        "weight": "0.30",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "simplified",
      },
    ],
  },
  {
    "featureType": "poi.attraction",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi.business",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi.government",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi.park",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi.place_of_worship",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi.school",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi.sports_complex",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi.medical",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "saturation": "-100",
      },
      {
        "visibility": "on",
      },
      {
        "color": Resources.contact_form.map.road_color_main,
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on",
      },
      {
        "color": Resources.contact_form.map.road_color_main,
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": Resources.contact_form.map.road_color_main,
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "lightness": "0",
      },
      {
        "color": Resources.contact_form.map.road_color_main,
      },
      {
        "weight": "0.50",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "lightness": "0",
      },
      {
        "color": Resources.contact_form.map.road_color_secondary,
      },
      {
        "weight": "0.50",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": Resources.contact_form.map.road_color_secondary,
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": Resources.contact_form.map.road_color_main,
      },
    ],
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": Resources.contact_form.map.road_color_main,
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.icon",
    "stylers": [
      {
        "invert_lightness": true,
      },
      {
        "saturation": "-7",
      },
      {
        "lightness": "3",
      },
      {
        "gamma": "1.80",
      },
      {
        "weight": "0.01",
      },
    ],
  },
  {
    "featureType": "transit",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": Resources.contact_form.map.secondary_color,
      },
    ],
  },
]

class CustomMap extends Component {
  render() {
    return (
      <CustomMapWrapper>
        <Map google={this.props.google}
             containerStyle={containerStyle}
             style={style}
             initialCenter={{
               lat: Number(this.props.siteContent.contact.lat),
               lng: Number(this.props.siteContent.contact.lng),
             }}
             zoom={Number(Resources.contact_form.map.zoom)}
             onReady={(mapProps, map) => this.mapLoaded(mapProps, map)}
        >
          <Marker name={Resources.strings.site_title}/>
        </Map>
      </CustomMapWrapper>
    )
  }

  mapLoaded(mapProps, map) {
    map.setOptions({
      styles: mapStyle
    })
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.GATSBY_GOOGLE_MAPS_KEY,
})(props => {
  const SiteContent = useSiteContent();
  return(<CustomMap siteContent={SiteContent} {...props}/>)
})
