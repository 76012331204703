import React from "react"
import styled from "styled-components"

const Wrapper = styled.section`
  width: 100%;
  background-color: var(--main-color-text);
  color: white;
  padding: 10px 0;

  .container {
  }
`

const RegulationFooter = () => {
  return (
    <Wrapper>
      <div className="container">
        <h4 className="title">Política de calidad</h4>

        <p className="description small-font">
          En Análisis Filtro e Índices SAPI de C. V. SOFOM E.N.R., Nos
          comprometemos a mejorar continuamente nuestros procesos de trabajo
          para poder beneficiar al mayor número de productores agropecuarios del
          campo mexicano y clientes de otros sectores productivos, brindando
          asesoría de calidad útil para la evaluación de sus proyectos y
          otorgando financiamiento oportuno y conveniente a sus necesidades.
          Para lograrlo debemos mantener una actitud de servicio al cliente
          altamente eficiente, plenamente humana y asegurar que nuestro servicio
          se cumple en estricto apego al marco legal aplicable.
        </p>
      </div>
    </Wrapper>
  )
}

export default RegulationFooter
