import React, {Component} from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import TextField from '@material-ui/core/TextField';
import Resources from '../../resources/afisofom-resources.json'

/**
 * Styles for CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 */
const CustomInputWrapper = styled.div`
    margin-top: 20px;
`;

/**
 * Class component of CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 */
class CustomInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMsg: Resources.strings.field_required
        };
    }

    render() {
        return (
            <CustomInputWrapper style={this.props.containerStyle}>
                <TextField error={this.props.invalid && this.props.submitted}
                           id={this.props.id}
                           name={this.props.name}
                           multiline={this.props.type === 'textarea'}
                           rowsMax={this.props.rowsMax}
                           rows={this.props.rowsMax}
                           type={this.props.type}
                           fullWidth
                           onChange={(e) => this.onChange(e)}
                           helperText={
                               this.props.invalid && this.props.submitted
                                   ? this.state.errorMsg
                                   : this.props.hint
                           }
                           label={this.props.label}
                           InputProps={this.props.inputProps}
                />
            </CustomInputWrapper>
        );
    }

    /**
     * Function to handle input change
     * @author @leonard_lib
     * @date 02/07/2020
     * @param event
     * @returns {*}
     */
    onChange(event) {
        const value = event.target.value;
        const valid = this.checkValid(value);
        return this.props.onChange(value, valid);
    };

    /**
     * Function to check input value when change
     * @author @leonard_lib
     * @date 02/07/2020
     * @param value
     * @returns {boolean}
     */
    checkValid(value) {
        let valid = true;
        let msg = null;

        if (this.props.required) {
            if (!(value != null && value !== '')) {
                valid = false;
                msg = Resources.strings.field_required;
            }
        }

        if (this.props.maxLength != null) {
            if (value.length > this.props.maxLength) {
                valid = false;
                msg = Resources.strings.maxlength_required;
            }
        }

        if (this.props.minLength != null) {
            if (value.length < this.props.minLength) {
                valid = false;
                msg = Resources.strings.minlength_required;
            }
        }

        if (this.props.type === 'email') {
            if (!this.isEmail(value)) {
                valid = false;
                msg = Resources.strings.email_required;
            }
        }

        this.setState({
            errorMsg: msg
        });
        return valid;
    }

    /**
     * Function to check if value is an email
     * @author @leonard_lib
     * @date 02/07/2020
     * @param value
     * @returns {boolean}
     */
    isEmail(value) {
        const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(value);
    }
}

/**
 * Property types of CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomInput.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    rowsMax: PropTypes.number,
    invalid: PropTypes.bool,
    submitted: PropTypes.bool,
    containerStyle: PropTypes.object,
    hint: PropTypes.string,
    inputProps: PropTypes.object,
    minLength: PropTypes.number,
    maxLength: PropTypes.number
};

/**
 * Default property values of CustomInput
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomInput.defaultProps = {
    required: false,
    rowsMax: 0,
    invalid: false,
    submitted: false,
    containerStyle: {},
    inputProps: {},
    hint: '',
    minLength: null,
    maxLength: null
};

export default CustomInput;
