import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

const CustomButtonWrapper = styled.div`
    .custom-color {
        color: var(${props => props.indicatorColor}) !important;
    }
    
    .primary-button {
        color: white !important;
        background-color: var(--main-color) !important;
        padding: 10px 60px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.9em !important;
    }

    .primary-button:hover {
        background-color: white !important;
        color: var(--primary-color) !important
    }


    .secondary-button {
        color: var(--main-color) !important;
        background-color: white !important;
        padding: 10px 60px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.9em !important;
    }

    .cover-button {
        color: var(--main-color) !important;
        background-color: white !important;
        padding: 10px 30px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.9em !important;
        filter: drop-shadow(0 2px 1px var(--contrast-background));
    }

    .cover-button:hover {
        background-color: var(--main-color) !important;
        color: white !important
    }

    .tertiary-button{
        color: var(--main-color);
        font-family: var(--font-bold);
        font-size: 16px;
        
        background-color: var(--main-background) !important;
        border: 1px solid var(--main-color);
        border-radius: 30px;
        padding: 40px 0px;

        /* Small devices (laptops/desktops, under 799px) */
        @media only screen and (max-width: 799px) {
            padding: 5px 0px;
            margin:0;
        }
    }

    .contacts-button {
        color: var(--main-color) !important;
        background-color: white !important;
        padding: 10px 60px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.9em !important;
    }

    .contacts-button:hover {
        background-color: var(--main-color) !important;
        color: white !important
    }

    .contact-form-button {
        color: white !important;
        background-color: var(--main-color) !important;
        padding: 10px 60px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.9em !important;
    }

    .contact-form-button:hover {
        background-color: white !important;
        color: var(--primary-color) !important
    }
    
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    .primary-button {
        font-size: 0.9em !important;
        padding: 10px 15px !important;
    }

    .secondary-button {
        font-size: 0.9em !important;
        padding: 10px 15px !important;
    }

    .contacts-button {
        padding: 10px 100px !important;
    }

    .contact-form-button {
        font-size: 0.9em !important;
        width: 100%;
        padding: 10px auto!important;
    }

  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .primary-button {
        font-size: 1em !important;
    }

    .secondary-button {
        font-size: 1em !important;
    }
  }
`;

const CustomButton = ({ label, onClick, isLoading, view, indicatorColor, containerStyle, fullWidth }) => (
    <CustomButtonWrapper style={containerStyle} indicatorColor={indicatorColor}>
        <Button variant="contained"
                fullWidth={fullWidth}
                className={view}
                onClick={onClick}
                disabled={isLoading}
                >
            {
                isLoading &&
                <CircularProgress size={24}
                                  className="custom-color" />
            }
            {
                !isLoading && label
            }
        </Button>
    </CustomButtonWrapper>
);

/**
 * Property types of CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    view: PropTypes.oneOf([
      'primary-button',
      'secondary-button',
      'tertiary-button',
      'cover-button',
      'contacts-button',
      'contact-form-button'
    ]),
    indicatorColor: PropTypes.string,
    containerStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
};

/**
 * Default property values for CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
CustomButton.defaultProps = {
    isLoading: false,
    view: 'primary-button',
    indicatorColor: '--main-background',
    containerStyle: {},
    fullWidth: true
};

export default CustomButton;
