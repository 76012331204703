import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress";

const SmallCustomButtonWrapper = styled.div`
    .small-custom-color {
        color: var(${props => props.indicatorColor}) !important;
    }
    
    .small-primary-button {
        color: white !important;
        background-color: var(--main-color) !important;
        padding: 3px 20px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.8em !important;
    }

    .small-secondary-button:hover {
        background-color: var(--main-color-2) !important;
        color: white !important
    }

    .small-secondary-button {
        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (min-width: 992px) and (max-width: 1280px) {
            padding: 1px 10px !important;
        }
        color: var(--main-color) !important;
        background-color: white !important;
        padding: 3px 20px !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.8em !important;
        border: none;
    }

    .small-tertiary-button {
        color: var(--main-background) !important;
        background-color: var(--main-color) !important;
        border-radius: 30px !important;
        font-family: var(--font-bold) !important;
        font-size: 0.8em !important;
        margin-left: 10px;
        max-width: 90%;
        margin-bottom: 2em;
        justify-self: center;
        align-self: end;
    }
`;

const SmallCustomButton = ({ label, onClick, isLoading, view, indicatorColor, containerStyle, fullWidth }) => (
    <SmallCustomButtonWrapper style={containerStyle} indicatorColor={indicatorColor}>
        <Button variant="contained"
                fullWidth={fullWidth}
                className={view}
                onClick={onClick}
                disabled={isLoading}
                disableElevation>
            {
                isLoading &&
                <CircularProgress size={24}
                                  className="custom-color" />
            }
            {
                !isLoading && label
            }
        </Button>
    </SmallCustomButtonWrapper>
);

/**
 * Property types of CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
SmallCustomButton.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    view: PropTypes.oneOf([
      'small-primary-button',
      'small-secondary-button',
      'small-tertiary-button'
    ]),
    indicatorColor: PropTypes.string,
    containerStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
};

/**
 * Default property values for CustomButton
 * @author @leonard_lib
 * @date 02/07/2020
 * @type Object
 */
SmallCustomButton.defaultProps = {
    isLoading: false,
    view: 'small-primary-button',
    indicatorColor: '--main-background',
    containerStyle: {},
    fullWidth: true
};

export default SmallCustomButton;
