import axios from 'axios';
import Resources from '../../resources/afisofom-resources.json';

export const sendContactForm = (name, email, business, phone, msg) => {
  return new Promise((resolve, reject) => {
    const path = process.env.GATSBY_CONTACT_FORM_URL;

    axios.post(path, {
      name: name,
      email: email,
      business: business,
      phone: phone,
      msg: msg,
      site: "Afisofom"
    }, {
      headers: {
        'x-api-key': process.env.GATSBY_CONTACT_FORM_API_KEY
      }
    }).then(res => {
      if (res.data && res.data.errorMessage) {
        console.log(res.data.errorMessage);
        reject(Resources.strings.contact_form_error);
      } else {
        resolve(Resources.strings.contact_form_success);
      }
    }).catch(error => {
      console.log(error);
      reject(Resources.strings.contact_form_error);
    });
  });
};

