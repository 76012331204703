import React, { Component } from "react"
import styled from "styled-components"
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaInstagram,
} from "react-icons/fa"
import Resources from "../../resources/afisofom-resources"
import SmallCustomButton from "./small-button"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import { format } from "../utils/string"

import AfiLogo from "../images/svg/afi-text-logo-white.svg"
import { useSiteContent } from "../hooks/use-site-content"

const PrimaryNavbarWrapper = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  max-width: 90%;
  transition: 0.5s;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    display: none;
    max-width: 100%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    display: none;
    max-width: 100%;
  }
`

const PrimaryNavbarList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
`

const PrimaryNavbarLink = styled.a`
  color: var(--navLinkColor);
  font-family: var(${Resources.navbar.primary.link.font});
  font-size: ${Resources.navbar.primary.link.size};
  text-decoration: none;
  text-transform: uppercase;
  margin-right: 22px;
  cursor: pointer;

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    margin-right: 18px;
  }
`

const PrimaryNavbarIconsList = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;

  .icon-primary {
    font-size: ${Resources.navbar.primary.icon.size} !important;
  }
`

const PrimaryNavbarIcon = styled.a`
  color: var(--navIconColor);
  font-family: var(${Resources.navbar.primary.icon.font});
  font-size: ${Resources.navbar.primary.icon.size};
  text-decoration: none;
  margin-right: 22px;
  align-self: flex-end;

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) and (max-width: 1279px) {
    margin-right: 18px;
  }
`

const AfiLogoStyled = styled(AfiLogo)`
  height: 1.5em;
  fill: var(--titleSVGColor);
`

class PrimaryNavbar extends Component {
  constructor() {
    super()
    this.state = {
      scrolled: false,
      location: "/",
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  /**
   * Scroll to the selected position.
   * @param {String} to
   */
  smoothTo(to) {
    const element = document.getElementById(to)
    if (element) {
      const position = element.offsetTop - Resources.navbar.offset_top
      window.scrollTo(0, position)
    }
  }

  /**
   * Triggered when an item is clicked.
   * @param {String} to
   * @param {String} location
   */
  onLinkClick(to, location) {
    if (location === "/") {
      this.smoothTo(to)
    } else {
      navigate("/")
      setTimeout(() => this.smoothTo(to), 500)
    }
  }

  /**
   * Action executed when the client button is clicked
   */
  onClickClient() {
    console.log("clicked")
  }

  /**
   * Every time the component updates, we check which color will be used for icons
   */
  componentDidUpdate() {
    const menuIcon = document.querySelector(".menu-mobile-icon")
    if (
      this.props.location === "/" &&
      document.scrollingElement.scrollTop < 160
    ) {
      menuIcon.style.color = "var(--main-color)"
    }
  }

  /**
   * Scroll handler
   * @param {*} e
   */
  handleScroll(e) {
    /**
     * The scroll handle will be only available con root location.
     */
    if (this.props.location === "/") {
      /** Get scroll position. */
      const scrollPosition = document.scrollingElement.scrollTop

      /** Background color set null */
      let backgroundColor = null

      /** We set the link and icon colors */

      const linkStartColor = this.cssVarToRgbArr(
        Resources.navbar.primary.link.color
      )
      const linkEndColor = this.cssVarToRgbArr(
        Resources.navbar.primary.link.end_color
      )
      let currentLinkColor = null

      const iconStartColor = this.cssVarToRgbArr(
        Resources.navbar.primary.icon.color
      )
      const iconEndColor = this.cssVarToRgbArr(
        Resources.navbar.primary.icon.end_color
      )
      let currentIconColor = null

      /** When the scroll top position is under 160px  */
      if (scrollPosition <= 160 && this.props.location === "/") {
        const opacity = scrollPosition / 160
        /** Setting the dynamic opacity when we scroll */
        backgroundColor = format(Resources.navbar.secondary.bg_color, [opacity])
        document.getElementById(
          "navbar-top"
        ).style.backgroundColor = backgroundColor

        currentIconColor = this.getCurrentColor(
          iconStartColor,
          iconEndColor,
          scrollPosition
        )
        this.rgbArrToCssVar("--navIconColor", currentIconColor)
        this.rgbArrToCssVar("--titleSVGColor", currentIconColor)

        currentLinkColor = this.getCurrentColor(
          linkStartColor,
          linkEndColor,
          scrollPosition
        )
        this.rgbArrToCssVar("--navLinkColor", currentLinkColor)

        const requestButton = document.getElementById("request-button")
        if (requestButton) {
          requestButton.style.transition = "opacity 0.25s"
          requestButton.style.opacity = 0

          /**
           * Timeout set to give some time to the social icons to dissapear
           * before the change of state
           */
          setTimeout(() => {
            this.setState(
              {
                scrolled: false,
              },
              () => {
                const socialList = document.getElementById("social-list")
                const menuIcon = document.querySelector(".menu-mobile-icon")
                socialList.style.transition = "opacity 0.25s"
                socialList.style.opacity = 0
                setTimeout(() => {
                  socialList.style.opacity = 1
                  menuIcon.style.color = "var(--main-color)"
                }, 50)
              }
            )
          }, 250)
        }
      } else {
        this.rgbArrToCssVar("--navIconColor", iconEndColor)
        this.rgbArrToCssVar("--navLinkColor", linkEndColor)
        this.rgbArrToCssVar("--titleSVGColor", linkEndColor)

        /**
         * This variable will help us to define which of the social list button or the
         * request button is displayed.
         */
        if (!this.state.scrolled) {
          backgroundColor = format(Resources.navbar.secondary.bg_color, ["1"])
          document.getElementById(
            "navbar-top"
          ).style.backgroundColor = backgroundColor
          const socialList = document.getElementById("social-list")
          const menuIcon = document.querySelector(".menu-mobile-icon")
          if (socialList) {
            socialList.style.transition = "opacity 0.25s"
            socialList.style.opacity = 0
            setTimeout(() => {
              this.setState(
                {
                  scrolled: true,
                },
                () => {
                  const requestButton = document.getElementById(
                    "request-button"
                  )
                  menuIcon.style.color = "white"
                  requestButton.style.transition = "opacity 0.25s"
                  requestButton.style.opacity = 0
                  setTimeout(() => {
                    requestButton.style.opacity = 1
                  }, 50)
                }
              )
            }, 250)

            setTimeout(() => {}, 1)
          }
        }
      }
    }
  }

  cssVarToRgbArr(varName) {
    let hex = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(varName)
    hex = hex.trim()
    if (hex.length <= 4) {
      let hexArr = hex.split("")
      hex =
        "#" +
        hexArr[1] +
        hexArr[1] +
        hexArr[2] +
        hexArr[2] +
        hexArr[3] +
        hexArr[3]
    }
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16)
    let rgbArray = [r, g, b]
    return rgbArray
  }

  rgbArrToCssVar(varName, arr) {
    let currentColor = "rgb(" + arr.join(", ") + ")"
    let root = document.documentElement
    root.style.setProperty(varName, currentColor)
  }

  getCurrentColor(startColor, endColor, increment) {
    let diff = [
      (startColor[0] - endColor[0]) / 160,
      (startColor[1] - endColor[0]) / 160,
      (startColor[2] - endColor[0]) / 160,
    ]
    let currentColor = [
      increment * diff[0] * -1 + startColor[0],
      increment * diff[1] * -1 + startColor[1],
      increment * diff[2] * -1 + startColor[2],
    ]

    return currentColor
  }

  componentDidMount() {
    // Workaroud para que no empiecen sin color si no se hace scroll
    const linkStartColor =
      this.props.location === "/"
        ? this.cssVarToRgbArr(Resources.navbar.primary.link.color)
        : [255, 255, 255]
    const iconStartColor =
      this.props.location === "/"
        ? this.cssVarToRgbArr(Resources.navbar.primary.icon.color)
        : [255, 255, 255]
    const menuIcon = document.querySelector(".menu-mobile-icon")
    this.rgbArrToCssVar("--titleSVGColor", linkStartColor)
    this.rgbArrToCssVar("--navIconColor", linkStartColor)
    this.rgbArrToCssVar("--navLinkColor", iconStartColor)

    if (this.props.location === "/") {
      menuIcon.style.color = "var(--main-color)"
    }

    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    let linkStartColor = this.cssVarToRgbArr(
      Resources.navbar.primary.link.color
    )
    let iconStartColor = this.cssVarToRgbArr(
      Resources.navbar.primary.icon.color
    )
    this.rgbArrToCssVar("--titleSVGColor", linkStartColor)
    this.rgbArrToCssVar("--navIconColor", linkStartColor)
    this.rgbArrToCssVar("--navLinkColor", iconStartColor)

    window.removeEventListener("scroll", this.handleScroll)
  }

  /**
   * render function
   */
  render() {
    const siteContent = this.props.siteContent
    return (
      <PrimaryNavbarWrapper id={"navbar-wrapper"}>
        <PrimaryNavbarList>
          <PrimaryNavbarLink
            onClick={() =>
              this.onLinkClick(
                Resources.navbar.link_logo.to,
                this.props.location
              )
            }
          >
            <AfiLogoStyled />
          </PrimaryNavbarLink>
          {Resources.navbar.links.map((link, key) => (
            <PrimaryNavbarLink
              key={key}
              onClick={() => this.onLinkClick(link.to, this.props.location)}
            >
              {link.label}
            </PrimaryNavbarLink>
          ))}

          <PrimaryNavbarLink
            className={"underline-text"}
            onClick={() => navigate("https://clientes.afisofom.com/afisofom/")}
          >
            {Resources.navbar.secondary.link_label}
          </PrimaryNavbarLink>
        </PrimaryNavbarList>
        {!this.state.scrolled && this.props.location === "/" && (
          <PrimaryNavbarIconsList id={"social-list"}>
            <PrimaryNavbarIcon
              href={siteContent.navbar.social_links.fb}
              target="_blank"
            >
              <FaFacebookF className="icon-primary" />
            </PrimaryNavbarIcon>
            <PrimaryNavbarIcon
              href={siteContent.navbar.social_links.twitter}
              target="_blank"
            >
              <FaTwitter className="icon-primary" />
            </PrimaryNavbarIcon>
            <PrimaryNavbarIcon
              href={siteContent.navbar.social_links.linkedin}
              target="_blank"
            >
              <FaLinkedinIn className="icon-primary" />
            </PrimaryNavbarIcon>
            <PrimaryNavbarIcon
              href={siteContent.navbar.social_links.instagram}
              style={{ marginRight: 0 }}
              target="_blank"
            >
              <FaInstagram className="icon-primary" />
            </PrimaryNavbarIcon>
          </PrimaryNavbarIconsList>
        )}

        {(this.state.scrolled || this.props.location !== "/") && (
          <PrimaryNavbarIconsList id={"request-button"}>
            <SmallCustomButton
              label={Resources.navbar.secondary.button.label}
              view={Resources.navbar.secondary.button.view}
              onClick={() =>
                navigate("https://clientes.afisofom.com/afisofom/solicitud")
              }
            />
          </PrimaryNavbarIconsList>
        )}
      </PrimaryNavbarWrapper>
    )
  }
}

/**
 * Property types of PrimaryNavbar
 */
PrimaryNavbarIconsList.propTypes = {
  location: PropTypes.string,
  siteConfig: PropTypes.object,
}

/**
 * Default property values for PrimaryNavBar
 */
PrimaryNavbar.defaultProps = {
  location: "/",
  siteConfig: {},
}

export default props => {
  const SiteContent = useSiteContent()
  return <PrimaryNavbar siteContent={SiteContent} {...props} />
}
